import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { refresh } from 'shared/api/Authorization/UserActions';
import { useAppDispatch } from 'shared/hooks/useAppDispatch/useAppDispatch';
import { classNames } from 'shared/lib/classNames/classNames';
import { AdminRouter } from '../../AdminRouter/AdminRouter';
import { AppRouter } from '../../AppRouter/AppRouter';

interface GlobalRouterProps {
    className?: string;
}

export const GlobalRouter: React.FC<GlobalRouterProps> = (props) => {
    const { className } = props;

    

    return (
        <>
            <AppRouter />
            <AdminRouter />
        </>
 );
}