import axios  from 'axios';
import { AppDispatch } from "app/store/store"
import { baseUrlAuth } from "shared/config/ApiConfig/ApiConfig"
import { IToken, IUser } from './types';
import { UserSlice } from './UserSlice';
import { access } from 'fs';

export const login = (data: FormData) => async (dispatch: AppDispatch) =>  {
    try {
        const url = `${baseUrlAuth}token/`
        const response = await axios.post<IToken>(url, data)
        dispatch(UserSlice.actions.setAuth())
        dispatch(UserSlice.actions.setAccess(response.data.access))
        dispatch(UserSlice.actions.setRefresh(response.data.refresh))
    } catch (e) {
        console.log(e)
    }
}

export const refresh = () => async (dispatch: AppDispatch) => {
    
    const url = `${baseUrlAuth}token/refresh/`
    const formData = new FormData()
    const refresh_token = localStorage.getItem('refresh')
    if (refresh_token) {
        formData.append('refresh', refresh_token)
        try {
            axios.post(url, formData)
            .then((response) => {
                dispatch(UserSlice.actions.setAccess(response.data.access))
                dispatch(UserSlice.actions.setAuth())
            })
            .catch(({response}) => {
                dispatch(UserSlice.actions.setUnAuth())
                localStorage.removeItem('access')
                localStorage.removeItem('refresh')
            })

        } catch (e) {
            console.log(e)
            dispatch(UserSlice.actions.setUnAuth())
        }
    }else{
        dispatch(UserSlice.actions.setUnAuth())
    }
}