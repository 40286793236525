import { useEffect, useState } from 'react';
import { Navigate, Outlet, useLocation, useNavigate } from 'react-router-dom';
import { refresh } from 'shared/api/Authorization/UserActions';
import { useAppDispatch, useAppSelector } from 'shared/hooks/useAppDispatch/useAppDispatch';
import cls from './PrivateRoute.module.scss';


interface PrivateRouteProps {
}

export const PrivateRoute: React.FC<PrivateRouteProps> = () => {


    const {pathname} = useLocation()
    // If authorized, return an outlet that will render child elements
    // If not, return element that will navigate to login page
    const navigatie = useNavigate()

    const dispatch = useAppDispatch()

    useEffect(
        () => {
        dispatch(refresh())
        }, [pathname]
    )


    useEffect(
        () => {
            if(localStorage.getItem('isAuth') === 'false'){
                navigatie('/admin/login')
            }
        }, []
    )
    

    return (
        localStorage.getItem('isAuth') === 'true' || pathname==='/admin/login' ? <Outlet /> : <Navigate to="/admin/login" />
    )
}