import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useLocation } from 'react-router';
import { refresh } from 'shared/api/Authorization/UserActions';
import {UserSlice} from 'shared/api/Authorization/UserSlice';
import { doctorsApi } from 'shared/api/Doctors/DoctrosApi';
import { IDoctor } from 'shared/api/Doctors/types';
import { useAppDispatch } from 'shared/hooks/useAppDispatch/useAppDispatch';
import { classNames } from 'shared/lib/classNames/classNames';
import cls from './DoctorForm.module.scss';

interface DoctorFormProps {
    className?: string;
    submitHandler: any;
    doctor?: IDoctor;
}

export const DoctorForm: React.FC<DoctorFormProps> = (props) => {
    const { className, submitHandler } = props;

    const location = useLocation()

    const params = new URLSearchParams(location.search);
    const dispatch = useAppDispatch()

    const [addDoctor, {isSuccess}] = doctorsApi.useAddDoctorMutation()
    const [updateDoctor, {}] = doctorsApi.useUpdateDoctorMutation()
    const [ fetchOneDoctor, {data: doctor, reset: resetDoctor}] = doctorsApi.useFetchOneDoctorMutation()





    const {
        register, 
        formState: {
          errors,
        },
        reset,
        handleSubmit,
    } = useForm({
        mode: 'onBlur'
      })


        useEffect(
        ()=>{
            if(location.pathname === '/admin/doctor_edit'){
                fetchOneDoctor(params.get('id'))
                .then(
                    // @ts-ignore
                    ({data}) => {
                        const doctor = data
                        reset(doctor)
                    },
                    error => {
                        if (error.status === 401){
                            dispatch(refresh())
                            resetDoctor()
                        }
                    }
                )

            }
        },
        []
    )
    
    function OnSubmitHandler() {
        if(location.pathname === '/admin/doctor_edit'){
            const formData = new FormData(document.forms[0])
            const file = formData.get('photo') as File
            if (!Boolean(file.name)){
                formData.delete('photo')
            }
            // @ts-ignore
            updateDoctor([formData, params.get('id')])
        }else if(location.pathname === '/admin/doctor_add'){
            const formData = new FormData(document.forms[0])
            addDoctor(formData)
            reset()
        }
        
    } 


    
    

    return (
        <form className="row mx-auto" onSubmit={handleSubmit(OnSubmitHandler)} encType='multipart/form-data' >
        <div className="col-md-12 text-center">
            {
                errors?.photo && <p className={cls.error}>{}</p> 
            }
            <img src={typeof(doctor?.photo)==='string' ? doctor.photo : ''} alt="" style={{width: '120px', height: '120px'}} />
            <div
                style={{marginBottom: "30px"}}
                className={
                    classNames(cls.input, {
                        [cls.inputError]: Boolean(errors?.photo)
                    }, ['input_1 d-flex align-items-center justify-content-center'])
                }
                >
                    
                <input type="file"
                   
                    {...register(
                        'photo', 
                        {
                            required: Boolean(doctor) ? false : 'Это поле обязательно для заполнения'
                        }
                    )}
                />
                <span className={classNames(cls.span, {active: Boolean(doctor)}, [])}>Выберете фото</span>
            </div>
        </div>
        
        <div className="col-md-12">
            {
                errors?.fio && <p className={cls.error}>{}</p> 
            }
            <div className={
                    classNames(cls.input, {
                        [cls.inputError]: Boolean(errors?.fio)
                    }, ['input_1'])
                }
                style={{marginBottom: "30px"}}>
                <input type="text" 
                    {...register(
                        'fio', 
                        {
                            required: 'Это поле обязательно для заполнения'
                        }
                    )}
                />
                <span className={classNames(cls.span, {active: Boolean(doctor)}, [])}>ФИО специалиста</span>
                
            </div>
        </div>
        <div className="col-md-12">
            {
                errors?.position && <p className={cls.error}>{}</p> 
            }
            <div className={
                    classNames(cls.input, {
                        [cls.inputError]: Boolean(errors?.position)
                    }, ['input_1'])
                } 
                style={{marginBottom: "30px"}}>
                <input type="text" 
                     {...register(
                        'position', 
                        {
                            required: 'Это поле обязательно для заполнения'
                        }
                    )}
                />
                <span className={classNames(cls.span, {active: Boolean(doctor)}, [])}>Занимаемая должность</span>
            </div>
        </div>
        <div className="col-md-12">
            {
                errors?.recording_order && <p className={cls.error}>{}</p> 
            }
            <div className={
                    classNames(cls.input, {
                        [cls.inputError]: Boolean(errors?.recording_order)
                    }, ['input_1'])
                } 
                style={{marginBottom: "30px"}}>
                <input type="text" 
                     {...register(
                        'recording_order', 
                        {
                            required: 'Это поле обязательно для заполнения'
                        }
                    )}
                />
                <span className={classNames(cls.span, {active: Boolean(doctor)}, [])}>Порядок записи на консультацию, прием, диагностику</span>
                
            </div>
        </div>
        <div className="col-md-12">
            {
                errors?.certificate_data && <p className={cls.error}>{}</p> 
            }
            <div className={
                    classNames(cls.input, {
                        [cls.inputError]: Boolean(errors?.certificate_data)
                    }, ['textarea_1'])
                } 
                style={{marginBottom: "30px"}}>
                <textarea 
                    {...register(
                        'certificate_data', 
                        {
                            required: 'Это поле обязательно для заполнения'
                        }
                    )}
                ></textarea>
                <span className={classNames(cls.span, {active: Boolean(doctor)}, [])}>Данные сертификата специалиста – специальность, срок действия сертификата,
                    соответствие занимающей должности</span>
                
            </div>
        </div>
        <div className="col-md-12">
            {
                errors?.additional_certificate_data && <p className={cls.error}>{}</p> 
            }
            <div className={
                    classNames(cls.input, {
                        [cls.inputError]: Boolean(errors?.additional_certificate_data)
                    }, ['textarea_1'])
                } style={{marginBottom: "30px"}}>
                <textarea 
                     {...register(
                        'additional_certificate_data', 
                        {
                            required: 'Это поле обязательно для заполнения'
                        }
                    )}
                ></textarea>
                <span className={classNames(cls.span, {active: Boolean(doctor)}, [])}>Дополнительные данные сертификата – кем выдан, когда выдан, уровень образования,
                    квалификация</span>
            </div>
        </div>
        <div className="col-md-12 d-flex flex-wrap justify-content-end">
            <button type='submit' className="btn btn-success text-white"><span className={classNames(cls.span, {active: Boolean(doctor)}, [])}>Сохранить</span></button>
        </div>
    </form>
 );
}